<template>
  <div>
    <!--面包屑导航-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>学校概况</el-breadcrumb-item>
      <el-breadcrumb-item>学校宣传片</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <el-row :gutter="20"><!--栅格中每个组件的间距，20px-->
        <el-col :span="6"><!--占的比重，一共24-->
          <el-button type="primary" @click="showAddDialog" v-if="videoUrl === ''">上传视频</el-button>
          <el-button type="primary" @click="showAddDialog" v-if="videoUrl !== ''">替换视频</el-button>
        </el-col>
      </el-row>
      <div class="video-box">
       <div class="video">
          <video :src="videoUrl" type="video/mp4" controls  controlslist="nodownload" disablePictureInPicture/>
        </div>
      </div>
    </el-card>
    <el-dialog title="上传视频" :visible.sync="addDialog"
               width="900px"  @close="closeAddDialog">
      <div class="upload" style="text-align: center;height: 280px;">
        <div class="upload-box">
          <div class="video-image">
            <img v-if="!isupload" src="../../assets/video_normal.png" alt="">
            <img v-else  src="../../assets/video_check.png" alt="">
          </div>
        </div>
        <div>
          <p class="tips">{{isupload ? filenames : '仅支持500M以内的mp4格式文件'}}</p>
          <el-upload  action="#" :http-request="httpRequest" style="height: 36px;"
                     :multiple="false" :show-file-list="false" :before-upload="beforeAvatarUpload">
            <el-button class="btn" size="medium">{{isupload ? '重新上传' : '上传视频'}}</el-button>
          </el-upload>
        </div>
        <el-button class="btn import_btn" size="medium" type="primary" v-if="isupload" @click="upload" :loading="btnLoading">上&nbsp;&nbsp;&nbsp;&nbsp;传</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      videoUrl: '',
      addDialog: false,
      isupload:false,//true已选择文件  false没有
      filenames:'',//上传的文件名
      httpData:{},//http数据转存
      btnLoading: false,//按钮加载效果
      // 上传图片的请求头
      headersObj: {
        Authorization: window.localStorage.getItem('token')
      },
    }
  },

  mounted () {
    this.getVideo()
  },
  methods: {

    async getVideo() {
      const { data: res } = await this.$http.get('/video/list')
      if (res.code === 200) {
        this.videoUrl = res.result.url
        console.log(this.videoUrl)
      } else {
        this.$message.error(res.message)
      }
    },
    //上传文件赋值
    httpRequest(http) {
      this.isupload = true;
      this.filenames = http.file.name;
      this.httpData = http;
      console.log(http)
      console.log(this.httpData)
    },
    //上传文件前类型判断
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 500;
      let type = file.name.substring(file.name.lastIndexOf('.') + 1);
      if (type === 'mp4') {

      } else {
        this.$message.error('上传文件只能是mp4格式!');
        return false;
      }
      if(file.size === 0) {
        this.$message.error('文件大小不允许为0KB，请重新选择');
        return false;
      }
      if(!isLt2M) {
        this.$message.error("上传文件大小不能超过 500MB!");
        return false;
      }
    },
    showAddDialog() {
      this.addDialog = true
    },
    closeAddDialog() {
        this.addDialog = false
        this.isupload = false,//true已选择文件  false没有
        this.filenames = '',//上传的文件名
        this.httpData = {}//http数据转存
    },
    //上传
    async upload() {
      console.log('提交上传')
      this.btnLoading = true;
      console.log(this.httpData.file)
      // 先创建一个FormData 对象
      let formData = new FormData();
      formData.append('file',this.httpData.file);
      await this.$http({
        url: '/video/add',
        data: formData,
        headers: {
          'Content-Type': "multipart/form-data; charset=UTF-8",
        },
        method:'post',
        timeout: 300000
      }).then(res => {
        let result = res.data;
        if (result.code === 200) {
          this.btnLoading = false;
          this.$message.success(result.message)
          this.closeAddDialog()
          this.getVideo()
        } else {
          this.btnLoading = false;
          this.$message.error(result.message);
        }
      }).catch(error => {
        this.uploadVideoLoading = false;
        this.$message.error('连接服务器失败!');
      });
    },
  }
}
</script>

<style scoped>
.video-box {height: 600px;margin-top: 30px;}
.video {width: 900px;height: 506px;background-color: #eee;}
.video video {width: 100%;height: 100%;object-fit: cover;}
.video-image {width:50px;height:50px;margin-top: 32px;margin-left: 75px;}
.video-image img {width: 100%;height: 100%;object-fit: cover;}
.upload-box {width: 200px;height: 113px;border: 1px dashed #eee;margin: 0 auto;}
.upload .tips{
  font-size:14px;
  color:#000;
  padding:13px 0;
}
.upload .btn{
  width: 112px;
  display:block;
  margin:0 auto;
  border-radius:20px;
  color:#000;
}
.upload .import_btn{
  color:#fff;
  margin-top:15px;
}
</style>

<style>

.avatar-uploader-icon {
  border: 1px dashed #d9d9d9 !important;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px !important;
  position: relative !important;
  overflow: hidden !important;
}
.avatar-uploader .el-upload:hover {
  border: 1px dashed #d9d9d9 !important;
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 300px;
  height: 178px;
  display: block;
}
.upload .el-upload {
  height: 36px;
}
</style>
